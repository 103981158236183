import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import SubmissionPage from 'website_deletion/SubmissionPage';
import NotificationPage from 'website_deletion/NotificationPage';
import ConfirmationPage from 'website_deletion/ConfirmationPage';

const App = () => {
  const [page, setPage] = useState(0);
  const [userInfo, setUserInfo] = useState({ agencyId: 0, memberId: 0 });
  const [infoType, setInfoType] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agency_id = params.get('agencyId');
    const member_id = params.get('memberId');
    if (agency_id && member_id) {
      setUserInfo({ agencyId: agency_id, memberId: member_id });
      setPage(2);
      setInfoType(1);
    }
  }, []);

  let content;
  if (page === 0) {
    content = <SubmissionPage onPage={() => setPage(1)} />;
  } else if (page === 1) {
    content = <NotificationPage infoType={infoType} onPage={() => setPage(0)} />;
  } else {
    content = <ConfirmationPage onPage={() => setPage(1)} userInfo={userInfo} />;
  }

  return <>{content}</>;
};

ReactDOM.render(<App />, document.getElementById('ixn-website-deletion'));
