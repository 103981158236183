import React, { useState, useCallback } from 'react';

import styled from 'styled-components';
import Select from 'react-select';
import PropTypes from 'prop-types';

import ErrorMessage from 'website_deletion/Error';
import Spinner from 'website_deletion/Spinner';

import { ApiUtils } from '../../utils/WebsiteDeletionApi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  padding-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  margin-bottom: 30px;
`;

const EmailDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const Error = styled.h5`
  margin: 5px 10px;
  font-size: 12px;
  color: red;
`;

const AgencyDiv = styled.div`
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-evenly;
`;

const colourStyles = {
  control: styles => ({ ...styles, width: 400 })
};

const SubmissionPage = ({ onPage }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [noRecord, setNoRecord] = useState({ isError: false, errorMessage: '' });
  const [isLoader, setIsLoader] = useState(false);
  const regex = /\S+@\S+\.\S+/;

  const isValidEmail = email => {
    return regex.test(email);
  };

  const onHandleEmailVerify = useCallback(async () => {
    if (!isValidEmail(emailAddress)) {
      setIsEmailValid(true);
    } else {
      try {
        setIsEmailValid(false);
        setIsLoader(true);
        const data = await ApiUtils.getAvailableAgencies({ email: emailAddress });
        if (data?.response?.status === 500 || data === '') {
          setNoRecord({ isError: true, errorMessage: "Account Record doesn't not exist" });
        } else if (Object.keys(data?.agencyMobileApps).length) {
          setNoRecord({ isError: false, errorMessage: '' });
          const output = data?.agencyMobileApps?.map(item => {
            return {
              value: item?.agency,
              label: item?.agency,
              agencyId: item?.agencyId
            };
          });
          setAgencies(output);
        } else {
          setNoRecord({ isError: true, errorMessage: "Agency Record doesn't not exist" });
        }
      } catch (e) {
        //handle Errors
      } finally {
        setIsLoader(false);
      }
    }
  }, [emailAddress]);

  const onHandleSubmit = async () => {
    const payload = {
      email: emailAddress,
      agencyId: selectedAgency.agencyId,
      url: 'https://app.ixn.io'
    };
    const data = await ApiUtils.memberDeleteNotification(payload);
    if (data.isSuccessStatusCode) onPage();
  };

  return (
    <Container animate={{ opacity: 1, y: 0 }} initial={{ opacity: 0, y: -50 }} transition={{ duration: 0.5 }}>
      {isLoader ? <Spinner /> : null}
      <FormContainer>
        <Title>Origination IXN Webpage</Title>
        <EmailDiv>
          <StyledInput onChange={e => setEmailAddress(e.target.value)} placeholder='Enter Email Address' type='text' value={emailAddress} />
          <StyledButton onClick={onHandleEmailVerify}>Verify</StyledButton>
        </EmailDiv>
        {isEmailValid && <Error>Invaild Email Address</Error>}
        {noRecord.isError && <ErrorMessage message={noRecord.errorMessage} />}
        {!isEmailValid && !noRecord.isError && Object?.keys(agencies).length ? (
          <AgencyDiv>
            <Select
              defaultValue={selectedAgency}
              isClearable={true}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              isSearchable={true}
              name='Agencies'
              onChange={setSelectedAgency}
              options={agencies}
              placeholder='Select Your Agency'
              styles={colourStyles}
            />
            <StyledButton onClick={onHandleSubmit}>Submit</StyledButton>
          </AgencyDiv>
        ) : null}
      </FormContainer>
    </Container>
  );
};

SubmissionPage.propTypes = {
  onPage: PropTypes.func
};

export default SubmissionPage;
