import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Content = styled.div`
  width: 45%;
  higth: 800px;
  background-color: #ffffff;
  padding: 20px 20px 30px 40px;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 16px;
  background-color: '#007bff';
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const NotificationPage = ({ infoType, onPage }) => {
  const isEmailNotification = infoType === 0;
  return (
    <Container animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <Content>
        <h2>{isEmailNotification ? 'We have just sent you the confirmation link, so please check your mail.' : 'Your agency member account has been deleted.'}</h2>
        <StyledButton onClick={isEmailNotification ? () => onPage() : () => window.close()} style={{ backgroundColor: isEmailNotification ? '#007bff' : 'red' }}>
          {isEmailNotification ? 'Go To Back' : 'Close'}
        </StyledButton>
      </Content>
    </Container>
  );
};

NotificationPage.propTypes = {
  bntTxt: PropTypes.string,
  infoType: PropTypes.number,
  onPage: PropTypes.func
};

export default NotificationPage;
