import Axios from 'axios';
import AppConfig from 'config/App';

const getAxios = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  return Axios.create({
    baseURL: AppConfig.BASE_WEB_DELETION_URL,
    headers
  });
};

export const ApiUtils = {
  async getAvailableAgencies(payload) {
    try {
      const response = await getAxios().post('/member_mobile_apps', payload);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async memberDeleteNotification(payload) {
    try {
      const response = await getAxios().post('/member_delete_notification', payload);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async memberAgencyDelete(payload) {
    try {
      const response = await getAxios().post('/member_agency_delete', payload);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
};
