import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ErrorContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  padding: 5px;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  text-align: center;
`;

function Error({ message }) {
  return (
    <ErrorContainer animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <ErrorMessage>
        <p>{message}</p>
      </ErrorMessage>
    </ErrorContainer>
  );
}

Error.propTypes = {
  message: PropTypes.string
};

export default Error;
