import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Spinner from 'website_deletion/Spinner';

import { ApiUtils } from '../../utils/WebsiteDeletionApi';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ConfirmationContent = styled.div`
  background-color: rgba(255, 255, 255);
  padding: 30px 30px;
  width: 400px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  margin: 10px 0px 0px 0px;
`;

const Message = styled.p`
  font-size: 16px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const Page = styled.div`
  background-color: rgba(255, 255, 255);
  padding: 10px 30px 30px 30px;
  width: 400px;
  border-radius: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ConfirmationPage = ({ onPage, userInfo }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState(0);

  const onHandleDeleteAgency = () => {
    setType(0);
    setIsPopup(true);
  };

  const onHandleConfirmDeleteAgency = async () => {
    if (type === 1) {
      setIsPopup(false);
    } else {
      const payload = {
        memberId: userInfo?.memberId,
        agencyId: userInfo?.agencyId
      };
      setIsLoader(true);
      const data = await ApiUtils.memberAgencyDelete(payload);
      if (data?.member_deleted) {
        setIsLoader(false);
        setTimeout(() => {
          onPage();
          setIsPopup(false);
        }, 500);
      } else {
        setIsLoader(false);
        setType(1);
      }
    }
  };

  return (
    <Container>
      {isLoader ? <Spinner /> : null}
      <ConfirmationContent>
        <Title>Confirmation Delete</Title>
        <Message>There will be a deletion of your agency member account.</Message>
        <StyledButton onClick={onHandleDeleteAgency}>Yes, Delete My Agency</StyledButton>
      </ConfirmationContent>
      {isPopup && (
        <Overlay>
          <Page>
            <h1>{type === 0 ? 'Are you sure ?' : 'Agency record not located'}</h1>
            <p>{type === 0 ? "Do you really want to delete the agency? after this process, you can't use your agency." : 'Your agency member account has already been removed !!'}</p>
            <Row>
              {type === 0 && (
                <StyledButton onClick={() => setIsPopup(false)} style={{ backgroundColor: 'red' }}>
                  No, Cancel
                </StyledButton>
              )}
              <StyledButton onClick={onHandleConfirmDeleteAgency}>{type === 0 ? 'Yes, Delete' : 'Okay'}</StyledButton>
            </Row>
          </Page>
        </Overlay>
      )}
    </Container>
  );
};

ConfirmationPage.propTypes = {
  onPage: PropTypes.func,
  userInfo: PropTypes.object
};

export default ConfirmationPage;
